import React from "react";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import Navbars from "./nav_bar";
import axios from "axios";
import Box from "@mui/material/Box";
const CurrStatus = () => {
  const [datas, setdatas] = useState([]);
  const [newstate, setnewstate] = useState();
  const [Tabledata, setTabledata] = useState([]);
  const baseURl =
    "https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/admin/queues/api/queues?activeQueue=companyQueue&status=waiting&page=1&jobsPerPage=10";
  useEffect(() => {
    axios.get(baseURl).then((res) => {
      setdatas(res.data);
    });
  }, []);

  const columns = [
    {
      name: "cname",
      label: "Company Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "director",
    //   label: "Director",
    //   options: {
    //    filter: true,
    //    sort: false,
    //   }
    //  },
    {
      name: "cin",
      label: "CIN Number",
      options: {
        filter: true,
        sort: false,
      },
    },

  
  ];

  const map_data = datas?.queues;
 

  const options = {
    selectableRows: false,
    rowsPerPage: 1000,
  };

  useEffect(() => {
    setnewstate(datas?.queues);
  });
  const [maptab, setmaptab] = useState();
  const [r, setr] = useState();

  useEffect(() => {
    if (newstate !== undefined) {
      setr(newstate[0].jobs?.map((item, id) => item.data));
    }
  });


  useEffect(() => {
    const new_map_data = r?.map((row, id) => {
      return {
        cname: row.company_name,
        cin: row.cin,
      };
    });
    setTabledata(new_map_data);
  });

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbars />
        <Box
          component="main"
          sx={{ flexGrow: 1, mr:6,ml:6,marginTop:8}}
        >
          <MUIDataTable
            title={"Current Status"}
            data={Tabledata}
            columns={columns}
            options={options}
          />
        </Box>
      </Box>
    </>
  );
};

export default CurrStatus;
