import React from "react";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";
import { Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

const Most_recent = () => {
  const [data_stats, setdata_stats] = useState([]);
  const [fal,setfal]=useState([]);

  const stats_url =
    "https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/stats";
  const fal_url =
  "https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/falcon-stats";

  useEffect(() => {
    axios.get(stats_url).then((res) => {
      setdata_stats(res.data);
    });
    axios.get(fal_url).then((res) => {
      setfal(res.data);
    });
  }, []);

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/* Df */}
        <Grid item xs={4} textAlign="center">
          <Card
            style={{
              color: "white",
              backgroundColor: "#275df2",
              alignItems: "center",
            }}
          >
            <Typography marginTop="10px"> Directors Found </Typography>
            <h3>{data_stats.directorCount}</h3>
          </Card>
        </Grid>
        {/* DC */}
        <Grid item xs={4} textAlign="center">
          <Card
            style={{
              color: "white",
              backgroundColor: "#275df2",
              alignItems: "center",
            }}
          >
            <Typography marginTop="10px"> Data Captured </Typography>
            <h3>{data_stats.dataCaptureCount}</h3>
          </Card>
        </Grid>
        {/* MOB */}
        <Grid item xs={4} textAlign="center">
          <Card
            style={{
              color: "white",
              backgroundColor: "#275df2",
              alignItems: "center",
            }}
          >
            <Typography marginTop="10px"> Mobile Number </Typography>
            <h3>{data_stats.phoneNumberFetch}</h3>
          </Card>
        </Grid>
      </Grid>
      <br></br>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3,mt:2 }}>
        {/* Df */}
        <Grid item xs={4} textAlign="center">
          <Card
            style={{
              color: "white",
              backgroundColor: "#275df2",
              alignItems: "center",
            }}
          >
            <Typography marginTop="10px"> Contact Failed </Typography>
            <h3>{data_stats.contactFailed}</h3>
          </Card>
        </Grid>
        {/* DC */}
        <Grid item xs={4} textAlign="center">
          <Card
            style={{
              color: "white",
              backgroundColor: "#275df2",
              alignItems: "center",
            }}
          >
            <Typography marginTop="10px"> Contact Captured </Typography>
            <h3>{data_stats.contactCaptured}</h3>
          </Card>
        </Grid>
        {/* MOB */}
        <Grid item xs={4} textAlign="center">
          <Card
            style={{
              color: "white",
              backgroundColor: "#275df2",
              alignItems: "center",
            }}
          >
            <Typography marginTop="10px"> Email Fetched </Typography>
            <h3>{data_stats.emailFetch}</h3>
          </Card>
        </Grid>
      </Grid>
      <br></br>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3,mt:2 }}>
        {/* Df */}
        <Grid item xs={4} textAlign="center">
          <Card
            style={{
              color: "white",
              backgroundColor: "#275df2",
              alignItems: "center",
            }}
          >
            <Typography marginTop="10px"> Falcon Company </Typography>
            <h3>{fal.falconSourceCompanyCount}</h3>
          </Card>
        </Grid>
        {/* DC */}
        <Grid item xs={4} textAlign="center">
          <Card
            style={{
              color: "white",
              backgroundColor: "#275df2",
              alignItems: "center",
            }}
          >
            <Typography marginTop="10px"> Falcon Directors </Typography>
            <h3>{fal.totalFalconSourceDirectorCount}</h3>
          </Card>
        </Grid>
        {/* MOB */}
        <Grid item xs={4} textAlign="center">
          <Card
            style={{
              color: "white",
              backgroundColor: "#275df2",
              alignItems: "center",
            }}
          >
            <Typography marginTop="10px"> Falcon Directors with Contact  </Typography>
            <h3>{fal.totalFalconDirectorSuccessfully}</h3>
          </Card>
        </Grid>
      </Grid>
      <br></br>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3,mt:2 }}>
        {/* Df */}
        <Grid item xs={4} textAlign="center">
          <Card
            style={{
              color: "white",
              backgroundColor: "#275df2",
              alignItems: "center",
            }}
          >
            <Typography marginTop="10px"> Falcon in Pipeline </Typography>
            <h3>{fal.totalFalconDataInPipeline}</h3>
          </Card>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <Card
            style={{
              color: "white",
              backgroundColor: "#275df2",
              alignItems: "center",
            }}
          >
            <Typography marginTop="10px"> Falcon Company Finished </Typography>
            <h3>{fal.falconSourceCompanyFinished}</h3>
          </Card>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <Card
            style={{
              color: "white",
              backgroundColor: "#275df2",
              alignItems: "center",
            }}
          >
            <Typography marginTop="10px"> Falcon Company Failed </Typography>
            <h3>{fal.falconSourceCompanyFailed}</h3>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Most_recent;
