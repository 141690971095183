import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Script App Chart - Weekly Data ",
    },
  },
};

export function Last_week_chart(props) {
  const {
    day0_count_mobile,
    day1_count_mobile,
    day2_count_mobile,
    day3_count_mobile,
    day4_count_mobile,
    day5_count_mobile,
    day6_count_mobile,
    day0_count_din,
    day1_count_din,
    day2_count_din,
    day3_count_din,
    day4_count_din,
    day5_count_din,
    day6_count_din,
    day0_count_email,
    day1_count_email,
    day2_count_email,
    day3_count_email,
    day4_count_email,
    day5_count_email,
    day6_count_email,
  } = props;
  const labels = [
    moment().subtract(0, "d").format("DD-MMMM-YYYY"),
    moment().subtract(1, "d").format("DD-MMMM-YYYY"),
    moment().subtract(2, "d").format("DD-MMMM-YYYY"),
    moment().subtract(3, "d").format("DD-MMMM-YYYY"),
    moment().subtract(4, "d").format("DD-MMMM-YYYY"),
    moment().subtract(5, "d").format("DD-MMMM-YYYY"),
    moment().subtract(6, "d").format("DD-MMMM-YYYY"),
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Directors Found",
        data: [
          day0_count_din,
          day1_count_din,
          day2_count_din,
          day3_count_din,
          day4_count_din,
          day5_count_din,
          day6_count_din,
          day6_count_din,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.4)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
      {
        label: "Contact Number",
        data: [
          day0_count_mobile,
          day1_count_mobile,
          day2_count_mobile,
          day3_count_mobile,
          day4_count_mobile,
          day5_count_mobile,
          day6_count_mobile,
          day6_count_mobile,

        ],
        backgroundColor: ["rgba(54, 162, 235, 0.4)"],
        borderColor: ["rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
      {
        label: "Email",
        data: [
          day0_count_email,
          day1_count_email,
          day2_count_email,
          day3_count_email,
          day4_count_email,
          day5_count_email,
          day6_count_email,
          day6_count_email,

        ],
        backgroundColor: ["rgba(255, 206, 86, 0.4)"],
        borderColor: ["rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
