import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useState } from "react";
import UploadButtons from "./upload";
import Status from "./status";
import CurrStatus from "./currstats";
import { useNavigate } from "react-router-dom";
import DownloadingIcon from "@mui/icons-material/Downloading";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import TableChartIcon from "@mui/icons-material/TableChart";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import Pipeline from "./pipeline";
import DataCaptured from "./datacaptured";
import ContactDetails from "./contactdetails";
import { Button } from "@mui/material";
import InsightsIcon from '@mui/icons-material/Insights';
import logo from "../../Logo/logo6.png"
const drawerWidth = 220;

export default function ResponsiveDrawer() {
  const [navon, setnavon] = useState("upload");
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", p:1 }}>
      <CssBaseline />
  
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor:"#d7dbe1c9",
          },
        }}
        variant="permanent"
        anchor="left"
      >
          <Toolbar>
            <img 
            src={logo}
            width={"150px"}
            />
          </Toolbar>          

        <Divider />

        <List >
          <ListItem
            disablePadding
            onClick={() => {
              navigate("/upload");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <InboxIcon style={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Upload to Zauba" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("/pas-3-upload");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <InboxIcon style={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Upload to PAS-3" />
            </ListItemButton>
            
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("/mca-upload");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <InboxIcon style={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Upload to MCA" />
            </ListItemButton>
            
          </ListItem>
          <Divider />
          <ListItem
            disablePadding
            onClick={() => {
              navigate("/current-status");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <DownloadingIcon style={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Current Status" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("/pipeline");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <AccountTreeIcon style={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText primary="In Pipeline" />
            </ListItemButton>
          </ListItem>
          <Divider />

          <ListItem
            disablePadding
            onClick={() => {
              navigate("/final-status");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <TableChartIcon style={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Final status" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("/data-captured");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <DocumentScannerIcon style={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Data Captured" />
            </ListItemButton>
          </ListItem>
          <Divider />

          <ListItem
            disablePadding
            onClick={() => {
              navigate("/contact-details");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <ContactMailIcon style={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Contact Details" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("/pass3-details");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <ContactMailIcon style={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Pass 3 Details" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem
            disablePadding
            onClick={() => {
              navigate("/stats");
            }}
          >
            <Divider />
            <ListItemButton>
              <ListItemIcon>
                <InsightsIcon style={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Stats" />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider /> 
      </Drawer>
      <Toolbar />
    </Box>
  );
}
