import { useState,useEffect } from 'react';
import * as React from 'react';
import ResponsiveDrawer from './code';
import Login_page from '../Login/login_page';

export default function Navbars() {
const [authenticated, setauthenticated] = useState(true);
  useEffect(() => {
  const loggedInUser = localStorage.getItem("authenticated");
  if (loggedInUser) {
  setauthenticated(loggedInUser);
  }
}, []);
return(
  <>
  {authenticated ? <ResponsiveDrawer/> : <Login_page/>}

  </>
)

}