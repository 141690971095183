import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/system";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

//main function
function FileUpload() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [checked_priority, setChecked_priority] = useState(false);
  const [cnf_string, setcnf_string] = useState("");
  const [file, setFile] = useState(null);
  const [excelData, setExcelData] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

//handledrop
  function handleDrop(e) {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(droppedFile);
        reader.onload = (e) => {
          const workbook = XLSX.read(e.target.result, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const data = XLSX.utils.sheet_to_json(worksheet);
          setExcelData(data);
        };
      }
    setFile(droppedFile);
  }
//file selection
  function handleFileSelect(e) {
    const selectedFile = e.target.files[0];
    e.preventDefault();
    if (selectedFile) {
      let reader = new FileReader();
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = (e) => {
        const workbook = XLSX.read(e.target.result, { type: "buffer" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);
        setExcelData(data);
      };
    }
    console.log("File name", selectedFile);
    setFile(selectedFile);
  }
  console.log("excel data",excelData)
//sumbits open dialog
  const handleSubmit = (e) => {
    console.log("def",checked,"piru",checked_priority);
    e.preventDefault();
    //Default
    if(checked===true){
        if (excelData.length > 0) {
            let payload = excelData;
            axios
              .post(
                "https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/add-company?priority=3",
                payload,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then(function (response) {
                toast.success("Sumbited Successfully");
                console.log("then", response.data);
                navigate("/current-status");
              })
              .catch((err) => {
                console.log(err);
              });
          }
        console.log("default")

    }
    //priority
    if(checked_priority===true){
        if (excelData.length > 0) {
            let payload = excelData;
            axios
              .post(
                "https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/add-company?priority=1",
                payload,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then(function (response) {
                toast.success("Sumbited Successfully");
                console.log("then", response.data);
                navigate("/current-status");
              })
              .catch((err) => {
                console.log(err);
              });
          }
        console.log("priority")

    }

  };
  //checkbox handle change
  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
    setChecked_priority(false);
  };
  const handleChangeCheck_p = (event) => {
    setChecked_priority(event.target.checked);
    setChecked(false);
  };

  return (
    <>
      <div
        className="dropzone"
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        {file ? (
          <div>
            <p>File selected: {file.name}</p>
            <button onClick={() => setFile(null)}>Clear file</button>
            <button onClick={handleClickOpen}>Upload</button>
          </div>
        ) : (
          <div>
            <p>Drag and drop a file or click here to select a file</p>
            <Button variant="contained" component="label">
              Select File For Zauba
              <input
                hidden
                multiple
                type="file"
                name="file_Data"
                onChange={handleFileSelect}
              />
            </Button>
          </div>
        )}
      </div>
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="sm"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Script App | Upload
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              What is the priority of this Dataset?
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography gutterBottom margin="1">
                Default
              </Typography>
              <Checkbox
                checked={checked}
                onChange={handleChangeCheck}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography gutterBottom margin="1">
                Priority
              </Typography>
              <Checkbox
                checked={checked_priority}
                onChange={handleChangeCheck_p}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
            {checked_priority === true && (
              <>
                <Typography gutterBottom margin="1">
                  please type "CONFIRM" to set priority
                </Typography>
                <TextField
                  width="250px"
                  margin="normal"
                  value={cnf_string}
                  required
                  fullWidth
                  id="email"
                  label="CONFIRM"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => {
                    setcnf_string(e.target.value);
                  }}
                />
              </>
            )}
            {console.log("val", cnf_string)}
            {checked_priority === false && checked === false && (
              <Typography gutterBottom margin="1">
                Please Select Default or Priority!
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              onClick={handleSubmit}
              disabled={
                (checked_priority === false && checked === false) ||
                (checked_priority === true && cnf_string !== "CONFIRM")
              }
            >
              SUMBIT
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
}

export default FileUpload;
