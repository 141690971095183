import React from "react";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import axios, { all } from "axios";
import Navbars from "./nav_bar";
import Box from "@mui/material/Box";
import moment from "moment";

function FetchData() {
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = 500;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/director-list?status=DIN_FETCH&page=${page}&size=${pageSize}`
        );
        const data = response.data;
        setAllData(data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return allData;
}

const Status = () => {
  const apiData = FetchData();
  const [Tabledata, setTabledata] = useState([]);
  const columns = [
    {
      name: "cname",
      label: "Company Name",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: "cin",
      label: "CIN Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "director",
      label: "Director",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "desg",
      label: "Designation",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "din",
      label: "DIN Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "fetch_date",
      label: "Fetch Date",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  let latestDate = null;
  apiData?.forEach(({ last_updated }) => {
    if (latestDate === null || last_updated > latestDate) {
      latestDate = last_updated;
    }
  });
  useEffect(() => {
    const map_data = apiData?.map((row, index) => {
      return {
        cname: row.company_name,
        cin: row.cin,
        director: row.name,
        din: row.din,
        desg: row.designation,
        date: row.appointment_date,
        fetch_date: moment(row.last_updated).format("DD MMMM YYYY"),
        phone_number: row.phone_number,
        email: row.email,
      };
    });
    setTabledata(map_data);
  }, [apiData]);
  const options = {
    selectableRows: false,
    rowsPerPageOptions: [10, 100, 1000, 1500, 2000],
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbars />
        <Box
          component="main"
          sx={{ flexGrow: 1, mr: 2, ml: 2, marginTop: 10 }}
          maxWidth="1050px"
        >
          <MUIDataTable
            title={"Final Status"}
            data={Tabledata}
            columns={columns}
            options={options}
          />
        </Box>
      </Box>
    </>
  );
};

export default Status;
