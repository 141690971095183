import React from "react";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Navbars from "./nav_bar";

const Pipeline = () => {
  const [datas, setdatas] = useState([]);
  const [newstate, setnewstate] = useState();
  const [Tabledata, setTabledata] = useState([]);
  const baseURl =
    "https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/admin/queues/api/queues?activeQueue=companyQueue&status=active&page=1&jobsPerPage=10";
  useEffect(() => {
    axios.get(baseURl).then((res) => {
      setdatas(res.data);
    });
  },[]);

  const columns = [
    {
      name: "cname",
      label: "Company Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "director",
    //   label: "Director",
    //   options: {
    //    filter: true,
    //    sort: false,
    //   }
    //  },
    {
      name: "cin",
      label: "CIN Number",
      options: {
        filter: true,
        sort: false,
      },
    },

    //  {
    //   name: "date",
    //   label: "Date of Incorporation",
    //   options: {
    //    filter: true,
    //    sort: false,
    //   }
    //  },
  ];

  const data = [
    { name: "Abc union of group", company: "1234", city: "22-12-22" },
    { name: "Def adani", company: "5678", city: "20-5-22" },
    { name: "Xyz Pvt LTD", company: "8578", city: "5-1-22" },
    { name: "Indian Pvt LTD", company: "108", city: "15-8-21" },
  ];
  const map_data = datas?.queues;
  console.log("maps", datas?.queues);

  useEffect(() => {
    console.log("iside", datas, datas);
    // setTabledata(map_data);
  }, [datas]);
  console.log("tab", Tabledata);
  const options = {
    selectableRows: false,
    rowsPerPage: 1000,
  };

  console.log("We", datas?.queues);
  useEffect(() => {
    setnewstate(datas?.queues);
  });
  const [maptab, setmaptab] = useState();
  const [r, setr] = useState();

  useEffect(() => {
    if (newstate !== undefined) {
      console.log("JI HUXUZ", newstate[0]);
      setr(newstate[0].jobs?.map((item, id) => item.data));
    }
  });
  console.log("r", r);
  // if(newstate!==undefined){
  //   console.log("JI HUXUZ",newstate[0])
  // }

  // console.log(rushah,"s")

  useEffect(() => {
    const new_map_data = r?.map((row, id) => {
      return {
        cname: row.company_name,
        cin: row.cin,
      };
    });
    setTabledata(new_map_data);
  });

  console.log("hi", newstate !== undefined, maptab);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbars />
        <Box
          component="main"
          sx={{ flexGrow: 1, marginTop: 10, paddingRight: 3 }}
        >
          <MUIDataTable
            title={"In Pipeline"}
            data={Tabledata}
            columns={columns}
            options={options}
          />
        </Box>
      </Box>
      <br></br>
    </>
  );
};

export default Pipeline;
