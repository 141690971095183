import React from "react";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import axios, { all } from "axios";
import Navbars from "../nav_bar";
import Box from "@mui/material/Box";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";

const Pass_ContactDetails = () => {
  //useStates
  const [datas3, setdatas3] = useState([]);
  const [from_date, setfrom_date] = useState(null);
  const [to_date, setto_date] = useState(null);
  const [bool, setbool] = useState(false);
  const [Tabledata, setTabledata] = useState([]);
  const [page, setpage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setcount] = useState();
  const [apiData, setApiData] = useState([]);
  const [Date_ApiData,setDate_ApiData]=useState([])
  //mui opts
  const options = {
    searchAlwaysOpen: true,
    filterType: "multiselect",
    selectableRows: false,
    rowsPerPageOptions: [10, 20, 50],
    responsive: "standard",
    serverSide: true,
    count: count,
    rowsPerPage: rowsPerPage,
    onTableChange: (action, tableState) => {
      console.log("action", action, tableState);
      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "changeRowsPerPage":
          changeRowsPerPage(tableState.rowsPerPage, tableState.page);
          break;
        default:
          console.log("action not handled.");
      }
    },

    // onChangePageL{handlepage(page)}
  };
  //cols
  const columns = [
    {
      name: "cname",
      label: "Company Name",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: "cin",
      label: "CIN Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "fetch_date",
      label: "Fetch Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "pass3_name",
      label: "Form name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "pass3_Date",
      label: "Pas3 Date",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  useEffect(() => {
    getData("", 0);
  }, []);
  console.log("apidata",apiData)
  const handledateFetch = () => {
    axios
      .get(
        `https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/pas-company-list?page=${page+1}&size=${rowsPerPage}?start=${moment(
          from_date
        ).format("MM-DD-YYYY")}&end=${moment(to_date).format("MM-DD-YYYY")}`
      )
      .then((res) => {
        const apidatas = res.data;
        setDate_ApiData(apidatas?.data);
      });
      setbool(true)
    };

  const getData = async (url, page) => {
    axios
      .get(
        `https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/pas-company-list?page=${
          page + 1
        }&size=${rowsPerPage}`
      )
      .then((response) => {
        // Process the response and update state with the additional data
        const apidatas = response.data;
        // Update state with the additional data
        setApiData(apidatas?.data);
        setcount(apidatas?.total);
      })
      .catch((error) => {
        console.log(error);
        setApiData(null);
      });
  };
  const changePage = (page) => {
    axios
      .get(
        `https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/pas-company-list?page=${
          page + 1
        }&size=${rowsPerPage}`
      )
      .then((response) => {
        // Process the response and update state with the additional data
        const apidatas = response.data;
        // Update state with the additional data
        setApiData(apidatas?.data);
        setpage(page);
      })
      .catch((error) => {
        console.log(error);
        setApiData(null);
      });
  };
  const changeRowsPerPage = (rows, page) => {
    setRowsPerPage(rows);
    axios
      .get(
        `https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/pas-company-list?page=${page+1}&size=${rows}`
      )
      .then((response) => {
        const apidatas = response.data;
        setApiData(apidatas?.data);
        setpage(page);
      })
      .catch((error) => {
        console.log(error);
        setApiData(null);
      });
  };
  console.log("counting_total", count);

  useEffect(() => {
    if (bool === true) {
      console.log("Data3", Date_ApiData);
      const map_data = Date_ApiData?.map((row, index) => {
        const date=new Date(row.created_at)
        var year = date.getFullYear();
        var month = date.getMonth() + 1; // Months are zero-based, so we add 1
        var day = date.getDate();
        console.log(day,month,year,"DATE",moment(row.created_at).format("DD MMMM YYYY"))
        return {
          cname:
            row.company_name === undefined ? row.llp_name : row.company_name,
          cin: row.cin,
          pass3_name: row.pas[0].formName,
          pass3_Date: moment(row.pas[0].date).format("DD MMMM YYYY"),
          // fetch_date: day+"-"+month+"-"+year,
          fetch_date:moment(row.created_at).format("DD-MMMM-YYYY"),
          phone_number: row.phone_number,
          email: row.email,
        };
      });
      setTabledata(map_data);
    }
    if (bool === false) {
      const map_data = apiData?.map((row, index) => {
        const date=new Date(row.created_at)
        var year = date.getFullYear();
        var month = date.getMonth() + 1; // Months are zero-based, so we add 1
        var day = date.getDate();
        console.log(day,month,year,"DATE",moment(row.created_at).format("DD MMMM YYYY"))

        return {
          cname: row.company_name,
          cin: row.cin,
          pass3_name: row.pas[0].formName,
          pass3_Date: moment(row.pas[0].date).format("DD-MMMM-YYYY"),
          din: row.din,
          desg: row.designation,
          date: row.appointment_date,
          // fetch_date: day+"-"+month+"-"+year,
          fetch_date:moment(row.created_at).format("DD-MMMM-YYYY"),
          phone_number: row.phone_number,
          email: row.email,
        };
      });
      setTabledata(map_data);
    }
  }, [datas3, apiData]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbars />
        <Box
          component="main"
          sx={{ flexGrow: 1, mr: 2, ml: 2, marginTop: 10 }}
          maxWidth="1050px"
        >
          <Box flexDirection="row">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{ mr: 1, mt: 1, mb: 1 }}
                inputFormat="DD/MM/YYYY"
                label="From"
                value={from_date}
                onChange={(newFrom_date) => {
                  setfrom_date(newFrom_date);
                }}
                renderInput={(params) => (
                  <TextField {...params} sx={{ margin: 1 }} required />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{ m: 1 }}
                label="To"
                inputFormat="dd/MM/yyyy"
                onChange={(newTo_date) => {
                  setto_date(newTo_date);
                }}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              onClick={handledateFetch}
              sx={{ m: 2, mt: 2 }}
            >
              Fetch
            </Button>
          </Box>
          {console.log("tableData",Tabledata)}
          <MUIDataTable
            title={"Contact Details"}
            data={Tabledata}
            columns={columns}
            options={options}
          />
        </Box>
      </Box>
    </>
  );
};

export default Pass_ContactDetails;
