// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAY5p04KtHHpfzLFt7IMWYGFJw3VMwoN04",
  authDomain: "script-app-7339b.firebaseapp.com",
  projectId: "script-app-7339b",
  storageBucket: "script-app-7339b.appspot.com",
  messagingSenderId: "770604743548",
  appId: "1:770604743548:web:f1c96667069f28d04c82f9",
  measurementId: "G-HMVTRQKFH9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth=getAuth();
export{app,auth}