import React from "react";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import axios from "axios";
import Navbars from "./nav_bar";
import Box from "@mui/material/Box";
import moment from "moment";

const DataCaptured = () => {
  const [datas, setdatas] = useState([]);
  const [Tabledata, setTabledata] = useState([]);
  const baseURl =
  "https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/director-list?status=DATA_CAPTURE_DONE";;
  useEffect(() => {
    axios.get(baseURl).then((res) => {
      setdatas(res.data);
    });
  },[]);

  let latestDate = null;
  datas?.data?.forEach(({ last_updated }) => {
    if (latestDate === null || last_updated>latestDate) {
      latestDate = last_updated;
    }
  });
  const columns = [
    {
      name: "cname",
      label: "Company Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "cin",
      label: "CIN Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "director",
      label: "Director",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "desg",
      label: "Designation",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "din",
      label: "DIN Number",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "date",
      label: "Appointment Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "fetch_date",
      label: "Fetch Date",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  let din=0;
  const output = [];
  let counterxx= datas?.data?.map((row,index)=>{
    if((row.din!==null &&  moment(row.last_updated).format("DD/MM/YYYY")===moment(latestDate).format("DD/MM/YYYY"))) din++; 
})

  useEffect(() => {
    const map_data = datas?.data?.map((row, index) => {
      const date = new Date(row.last_updated);
      const day = date.getDate();
      const monthName = date.toLocaleString("default", { month: "long" });
      const year = date.toLocaleDateString("en-US", { year: "numeric" });
      const formattedDate = `${day} ${monthName} ${year}`;

      return {
        cname: row.company_name,
        cin: row.cin,
        director: row.name,
        din: row.din,
        desg: row.designation,
        date: row.appointment_date,
        fetch_date: formattedDate,
      };
    });
    setTabledata(map_data);
  }, [datas]);
  const options = {
    selectableRows: false,
    rowsPerPageOptions: [10, 1500, 2000,5000,50000],
  };

  var myobj = {};
  var obj;
  let i;
  let cname = [];
  let dname = [];
  const conuter = () => {
    datas?.map((item, index) => {
      item.director_list.map((d, ix) => {
        cname.push(item.company_name);
        dname.push(d.name);
      });
    });
  };


  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbars />
        <Box
          component="main"
          sx={{ flexGrow: 1, mr:2,ml:2,marginTop:10}}

        >
          <h3>Data captured on {moment(latestDate).format("DD MMMM YYYY")} : {din}</h3>
          <MUIDataTable
            title={"Data Captured"}
            data={Tabledata}
            columns={columns}
            options={options}
          />
        </Box>
      </Box>
    </>
  );
};

export default DataCaptured;
