import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";
import { Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Last_week_chart } from "../chart/last_week_chart";
const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  //color: theme.palette.primary.darker,
  //backgroundColor: theme.palette.primary.darker
}));
const Last_Week = () => {
  const [datas, setdatas] = useState([]);
  const [day0, setday0] = useState([]);
  const [day1, setday1] = useState([]);
  const [day2, setday2] = useState([]);
  const [day3, setday3] = useState([]);
  const [day4, setday4] = useState([]);
  const [day5, setday5] = useState([]);
  const [day6, setday6] = useState([]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const [director, setdirector] = useState([]);
  const [data_captured, setdata_captured] = useState([]);
  // const baseURl =
  //   "https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/director-list?status=CONTACT_FETCH_SUCCESSFULLY&s";
  // useEffect(() => {
  //   axios.get(baseURl).then((res) => {
  //     setdatas(res.data);
  //   });
  // }, [datas]);

  useEffect(() => {
    let from_date = moment().subtract(6, "d").format("MM-DD-YYYY");
    let to_date = moment().subtract(6, "d").format("MM-DD-YYYY");
    axios
      .get(
        `https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/download-data?start=${from_date}&end=${to_date}`
      )
      .then((res) => {
        setday6(res.data);
      });
  }, []);
  useEffect(() => {
    let from_date = moment().subtract(5, "d").format("MM-DD-YYYY");
    let to_date = moment().subtract(5, "d").format("MM-DD-YYYY");
    axios
      .get(
        `https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/download-data?start=${from_date}&end=${to_date}`
      )
      .then((res) => {
        setday5(res.data);
      });
  }, []);
  useEffect(() => {
    let from_date = moment().subtract(4, "d").format("MM-DD-YYYY");
    let to_date = moment().subtract(4, "d").format("MM-DD-YYYY");
    axios
      .get(
        `https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/download-data?start=${from_date}&end=${to_date}`
      )
      .then((res) => {
        setday4(res.data);
      });
  }, []);
  useEffect(() => {
    let from_date = moment().subtract(3, "d").format("MM-DD-YYYY");
    let to_date = moment().subtract(3, "d").format("MM-DD-YYYY");
    axios
      .get(
        `https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/download-data?start=${from_date}&end=${to_date}`
      )
      .then((res) => {
        setday3(res.data);
      });
  }, []);
  useEffect(() => {
    let from_date = moment().subtract(2, "d").format("MM-DD-YYYY");
    let to_date = moment().subtract(2, "d").format("MM-DD-YYYY");
    axios
      .get(
        `https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/download-data?start=${from_date}&end=${to_date}`
      )
      .then((res) => {
        setday2(res.data);
      });
  }, []);
  useEffect(() => {
    let from_date = moment().subtract(1, "d").format("MM-DD-YYYY");
    let to_date = moment().subtract(1, "d").format("MM-DD-YYYY");
    axios
      .get(
        `https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/download-data?start=${from_date}&end=${to_date}`
      )
      .then((res) => {
        setday1(res.data);
      });
  }, []);
  useEffect(() => {
    let from_date = moment().subtract(0, "d").format("MM-DD-YYYY");
    let to_date = moment().subtract(0, "d").format("MM-DD-YYYY");
    axios
      .get(
        `https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/download-data?start=${from_date}&end=${to_date}`
      )
      .then((res) => {
        setday0(res.data);
      });
  }, []);
  let day0_count_mobile = 0;
  let day0_count_email = 0;
  let day0_count_din = 0;

  day0?.data?.map((row, index) => {
    if (
      row.phone_number !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(0, "d").format("DD MMMM YYYY")
    )
      day0_count_mobile++;
    if (
      row.email !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(0, "d").format("DD MMMM YYYY")
    )
      day0_count_email++;
    if (
      row.din !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(0, "d").format("DD MMMM YYYY")
    )
      day0_count_din++;
  });

  let day1_count_mobile = 0;
  let day1_count_email = 0;
  let day1_count_din = 0;

  day1?.data?.map((row, index) => {
    if (
      row.phone_number !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(1, "d").format("DD MMMM YYYY")
    )
      day1_count_mobile++;
    if (
      row.email !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(1, "d").format("DD MMMM YYYY")
    )
      day1_count_email++;
    if (
      row.din !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(1, "d").format("DD MMMM YYYY")
    )
      day1_count_din++;
  });

  let day2_count_mobile = 0;
  let day2_count_email = 0;
  let day2_count_din = 0;

  day2?.data?.map((row, index) => {
    if (
      row.phone_number !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(2, "d").format("DD MMMM YYYY")
    )
      day2_count_mobile++;
    if (
      row.email !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(2, "d").format("DD MMMM YYYY")
    )
      day2_count_email++;
    if (
      row.din !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(2, "d").format("DD MMMM YYYY")
    )
      day2_count_din++;
  });
  let day3_count_mobile = 0;
  let day3_count_email = 0;
  let day3_count_din = 0;

  day3?.data?.map((row, index) => {
    if (
      row.phone_number !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(3, "d").format("DD MMMM YYYY")
    )
      day3_count_mobile++;
    if (
      row.email !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(3, "d").format("DD MMMM YYYY")
    )
      day3_count_email++;
    if (
      row.din !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(3, "d").format("DD MMMM YYYY")
    )
      day3_count_din++;
  });
  let day4_count_mobile = 0;
  let day4_count_email = 0;
  let day4_count_din = 0;

  day4?.data?.map((row, index) => {
    if (
      row.phone_number !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(4, "d").format("DD MMMM YYYY")
    )
      day4_count_mobile++;
    if (
      row.email !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(4, "d").format("DD MMMM YYYY")
    )
      day4_count_email++;
    if (
      row.din !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(4, "d").format("DD MMMM YYYY")
    )
      day4_count_din++;
  });
  let day5_count_mobile = 0;
  let day5_count_email = 0;
  let day5_count_din = 0;

  day5?.data?.map((row, index) => {
    if (
      row.phone_number !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(5, "d").format("DD MMMM YYYY")
    )
      day5_count_mobile++;
    if (
      row.email !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(5, "d").format("DD MMMM YYYY")
    )
      day5_count_email++;
    if (
      row.din !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(5, "d").format("DD MMMM YYYY")
    )
      day5_count_din++;
  });
  let day6_count_mobile = 0;
  let day6_count_email = 0;
  let day6_count_din = 0;

  day6?.data?.map((row, index) => {
    if (
      row.phone_number !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(6, "d").format("DD MMMM YYYY")
    )
      day6_count_mobile++;
    if (
      row.email !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(6, "d").format("DD MMMM YYYY")
    )
      day6_count_email++;
    if (
      row.din !== null &&
      moment(row.last_updated).format("DD MMMM YYYY") ===
        moment().subtract(6, "d").format("DD MMMM YYYY")
    )
      day6_count_din++;
  });

  return (
    <>
      <Last_week_chart
        day0_count_din={day0_count_din}
        day1_count_din={day1_count_din}
        day2_count_din={day2_count_din}
        day3_count_din={day3_count_din}
        day4_count_din={day4_count_din}
        day5_count_din={day5_count_din}
        day6_count_din={day6_count_din}
        day0_count_mobile={day0_count_mobile}
        day1_count_mobile={day1_count_mobile}
        day2_count_mobile={day2_count_mobile}
        day3_count_mobile={day3_count_mobile}
        day4_count_mobile={day4_count_mobile}
        day5_count_mobile={day5_count_mobile}
        day6_count_mobile={day6_count_mobile}
        day0_count_email={day0_count_email}
        day1_count_email={day1_count_email}
        day2_count_email={day2_count_email}
        day3_count_email={day3_count_email}
        day4_count_email={day4_count_email}
        day5_count_email={day5_count_email}
        day6_count_email={day6_count_email}
      />
      <br></br>
    </>
  );
};

export default Last_Week;
