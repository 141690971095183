import "./App.css";
import Login_page from "./Components/Login/login_page";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbars from "./Components/Navigation_Menu/nav_bar";
import UploadButtons from "./Components/Navigation_Menu/upload";
import CurrStatus from "./Components/Navigation_Menu/currstats";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pipeline from "./Components/Navigation_Menu/pipeline";
import DataCaptured from "./Components/Navigation_Menu/datacaptured";
import ContactDetails from "./Components/Navigation_Menu/contactdetails";
import Status from "./Components/Navigation_Menu/status";
import Stats from "./Components/Navigation_Menu/stats";
import Pas3FileUpload from "./Components/Navigation_Menu/PAS_3/Pas3_upload";
import MCAFileUpload from "./Components/Navigation_Menu/MCA_upload/MCA_upload";
import Pass_ContactDetails from "./Components/Navigation_Menu/PAS_3/pass_table";

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Login_page />} />
          <Route path="/upload" element={<UploadButtons />} />
          <Route path="/pas-3-upload" element={<Pas3FileUpload />} />
          <Route path="/mca-upload" element={<MCAFileUpload />} />
          <Route path="/current-status" element={<CurrStatus />} />
          <Route path="/pipeline" element={<Pipeline />} />
          <Route path="/final-status" element={<Status />} />
          <Route path="/data-captured" element={<DataCaptured />} />
          <Route path="/contact-details" element={<ContactDetails />} />
          <Route path="/pass3-details" element={<Pass_ContactDetails />} />
          <Route path="/stats" element={<Stats />} />
          
        </Routes>
      </Router>
    </>
  );
}

export default App;
