import * as React from "react";
import Stack from "@mui/material/Stack";
import Navbars from "./nav_bar";
import Box from "@mui/material/Box";
import FileUpload from "./upload_drag_drop/fileuploader";

export default function UploadButtons() {


  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbars />
        <Box component="main" sx={{ flexGrow: 1, m: 10, p: 1 }}>
          <Stack direction="row" alignItems="center" spacing={4} marginLeft="80px" marginTop="20px" >
            <FileUpload/>
          </Stack>
        </Box>
      </Box>
    </>
  );
}
