import React from "react";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import axios, { all } from "axios";
import Navbars from "./nav_bar";
import Box from "@mui/material/Box";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
import Papa from "papaparse";
import FileSaver from "file-saver";
import { CircularProgress } from "@mui/material";
import { Typography } from "@mui/material";

function FetchData() {
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = 500;


  return allData;
}

const ContactDetails = () => {
  const [datas3, setdatas3] = useState([]);
  const [from_date, setfrom_date] = useState(null);
  const [to_date, setto_date] = useState(null);
  const [bool, setbool] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloading, setdownloading] = useState(false);

  const allData23 = FetchData();
  const [Tabledata, setTabledata] = useState([]);
  const baseURl =
    "https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/director-list?status=CONTACT_FETCH_SUCCESSFULLY&total=300";
  const baseURl2 =
    "https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/director-list?status=CONTACT_FETCH_SUCCESSFULLY&page=2&size=100";
  let from = null;
  let to = null;
  const handledateFetch = () => {
    setLoading(true); // Set loading state to true before making the API request

    axios
      .get(
        `https://5vhsoeyo5k.execute-api.ap-south-1.amazonaws.com/download-data?start=${moment(
          from_date
        ).format("MM-DD-YYYY")}&end=${moment(to_date).format("MM-DD-YYYY")}`
      )
      .then((res) => {
        setdatas3(res.data);
        setLoading(false); // Set loading state to false after receiving the API response
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // Set loading state to false in case of an error
      });

    setbool(true);
  };

  const handledateDownload = () => {
    setdownloading(true);
    axios
      .get(
        `https://api.startupfino.in/task-manager/download_director_data/?start=${moment(
          from_date
        ).format("YYYY-MM-DD")}&end=${moment(to_date).format("YYYY-MM-DD")}`
      )
      .then((res) => {
        setdatas3(res.data);
        console.log("data", res.data);
        // const dataString = Papa.unparse(res.data, {});
        const blob = new Blob([res.data], { type: "text/csv;charset=utf-8" });
        FileSaver.saveAs(
          blob,
          `Data(${moment(from_date).format("DD-MMM-YY ")}-to-${moment(
            to_date
          ).format("DD-MMM-YY")}).csv`
        );
        setdownloading(false);
      });
    setbool(true);
  };

  const columns = [
    {
      name: "cname",
      label: "Company Name",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: "cin",
      label: "CIN Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "director",
      label: "Director",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "desg",
      label: "Designation",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "din",
      label: "DIN Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "fetch_date",
      label: "Fetch Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phone_number",
      label: "Phone Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  useEffect(() => {
    if (bool === true) {
      console.log("Data3", datas3);
      const map_data = datas3?.data?.map((row, index) => {
        return {
          cname:
            row.company_name === undefined ? row.llp_name : row.company_name,
          cin: row.cin,
          director: row.name === undefined ? row.director_name : row.name,
          din: row.din,
          desg: row.designation,
          date: row.appointment_date,
          fetch_date: moment(row.last_updated).format("DD MMMM YYYY"),
          phone_number: row.phone_number,
          email: row.email,
        };
      });
      setTabledata(map_data);
    }
    if (bool === false) {
      const map_data = allData23?.map((row, index) => {
        return {
          cname: row.company_name,
          cin: row.cin,
          director: row.name,
          din: row.din,
          desg: row.designation,
          date: row.appointment_date,
          fetch_date: moment(row.last_updated).format("DD MMMM YYYY"),
          phone_number: row.phone_number,
          email: row.email,
        };
      });
      setTabledata(map_data);
    }
  }, [datas3, allData23]);
  const options = {
    selectableRows: false,
    rowsPerPageOptions: [10, 1000, 1500, 2000, 50000],
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbars />

        <Box
          component="main"
          sx={{ flexGrow: 1, mr: 2, ml: 2, marginTop: 10 }}
        >
          <Box flexDirection="row">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{ mr: 1, mt: 1, mb: 1 }}
                format="DD/MM/YYYY"
                label="From"
                value={from_date}
                onChange={(newFrom_date) => {
                  setfrom_date(newFrom_date);
                }}
                renderInput={(params) => (
                  <TextField {...params} sx={{ margin: 1 }} required />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{ m: 1 }}
                label="To"
                format="DD/MM/YYYY"
                onChange={(newTo_date) => {
                  setto_date(newTo_date);
                }}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              onClick={handledateDownload}
              sx={{ m: 2, mt: 2 }}
               // Disable the download button when loading is true
            >
              {downloading ? (
                "Downloading..." // Display the loader when loading is true
              ) : (
                "Download" // Display the download button text when loading is false
              )}
            </Button>
          </Box>
          {downloading ? (
                <>
                <CircularProgress size={24} />
                <Typography variant="h6">
                  Please Wait... Downloading Data
                  </Typography>
                </> // Display the loader when loading is true
              ) : (
                <></>
              )}
        </Box>
      </Box>
    </>
  );
};

export default ContactDetails;
